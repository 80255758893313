var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    {
      ref: "reportingRef",
      staticClass: "standards-reporting",
      attrs: { color: "#ffffff" }
    },
    [
      _c(
        "v-form",
        { ref: "reportingForm" },
        [
          _vm.$vuetify.breakpoint.smAndDown
            ? _c(
                "v-sheet",
                { staticClass: "mx-auto", attrs: { elevation: "0" } },
                [
                  _c(
                    "v-slide-group",
                    {
                      staticClass: "py-2",
                      attrs: { "center-active": "" },
                      model: {
                        value: _vm.localSlideGroupModel,
                        callback: function($$v) {
                          _vm.localSlideGroupModel = $$v
                        },
                        expression: "localSlideGroupModel"
                      }
                    },
                    [
                      _c(
                        "v-slide-item",
                        { key: 0 },
                        [
                          _c("v-autocomplete", {
                            staticClass: "ml-2 pill-mobile-filters",
                            staticStyle: {
                              "max-width": "200px",
                              "font-size": "12px"
                            },
                            attrs: {
                              rounded: "",
                              dense: "",
                              flat: "",
                              solo: "",
                              outlined: "",
                              "hide-details": "auto",
                              items: _vm.localClassItems
                            },
                            model: {
                              value: _vm.reportClassId,
                              callback: function($$v) {
                                _vm.reportClassId = $$v
                              },
                              expression: "reportClassId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-slide-item",
                        { key: 1 },
                        [
                          _c("v-autocomplete", {
                            staticClass: "ml-2 pill-mobile-filters",
                            staticStyle: {
                              "max-width": "200px",
                              "font-size": "12px"
                            },
                            attrs: {
                              rounded: "",
                              dense: "",
                              flat: "",
                              solo: "",
                              outlined: "",
                              "hide-details": "auto",
                              items: _vm.activeFrameworkItems
                            },
                            model: {
                              value: _vm.reportState,
                              callback: function($$v) {
                                _vm.reportState = $$v
                              },
                              expression: "reportState"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-slide-item",
                        { key: 2 },
                        [
                          _c("v-select", {
                            staticClass: "ml-2 pill-mobile-filters",
                            staticStyle: {
                              "max-width": "200px",
                              "font-size": "12px"
                            },
                            attrs: {
                              rounded: "",
                              dense: "",
                              flat: "",
                              solo: "",
                              outlined: "",
                              "hide-details": "auto",
                              items: _vm.gradeItems
                            },
                            model: {
                              value: _vm.reportGrade,
                              callback: function($$v) {
                                _vm.reportGrade = $$v
                              },
                              expression: "reportGrade"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-slide-item",
                        { key: 3 },
                        [
                          _c("v-autocomplete", {
                            staticClass: "ml-2 pill-mobile-filters",
                            staticStyle: {
                              "max-width": "200px",
                              "font-size": "12px"
                            },
                            attrs: {
                              rounded: "",
                              dense: "",
                              flat: "",
                              solo: "",
                              outlined: "",
                              "hide-details": "auto",
                              items: _vm.subjectItems
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(item.text)
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1475939010
                            ),
                            model: {
                              value: _vm.reportSubject,
                              callback: function($$v) {
                                _vm.reportSubject = $$v
                              },
                              expression: "reportSubject"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-slide-item",
                        { key: 4 },
                        [
                          _c("v-autocomplete", {
                            staticClass: "ml-2 pill-mobile-filters",
                            staticStyle: {
                              "max-width": "200px",
                              "font-size": "12px"
                            },
                            attrs: {
                              rounded: "",
                              dense: "",
                              flat: "",
                              solo: "",
                              outlined: "",
                              "hide-details": "auto",
                              items: _vm.categoryItems
                            },
                            on: { blur: _vm.validate, focus: _vm.validate },
                            model: {
                              value: _vm.reportCategory,
                              callback: function($$v) {
                                _vm.reportCategory = $$v
                              },
                              expression: "reportCategory"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-slide-item",
                        { key: 5 },
                        [
                          _c("pb-date-time-range-field", {
                            ref: "dateTimeRange",
                            staticClass: "ml-2",
                            attrs: {
                              pill: true,
                              "hide-details": "auto",
                              start: _vm.reportStart,
                              end: _vm.reportEnd,
                              rules: _vm.dateRangeRules
                            },
                            on: {
                              "update:start": function($event) {
                                _vm.reportStart = $event
                              },
                              "update:end": function($event) {
                                _vm.reportEnd = $event
                              },
                              blur: _vm.validate,
                              focus: _vm.validate
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "rounded-0", attrs: { flat: "" } },
                    [
                      !_vm.showStandardLessons
                        ? _c(
                            "v-container",
                            {
                              ref: "searchForm",
                              staticClass: "px-10",
                              class: {
                                "pt-5":
                                  !_vm.$vuetify.breakpoint.smAndDown ||
                                  _vm.$currentUser.isAdmin,
                                "pt-0": _vm.$vuetify.breakpoint.smAndDown
                              }
                            },
                            [
                              _vm.$vuetify.breakpoint.mdAndUp
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "4" } },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              dense: "",
                                              flat: "",
                                              outlined: "",
                                              "hide-details": "auto",
                                              label: _vm.$t("classLabel"),
                                              items: _vm.localClassItems
                                            },
                                            model: {
                                              value: _vm.reportClassId,
                                              callback: function($$v) {
                                                _vm.reportClassId = $$v
                                              },
                                              expression: "reportClassId"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "4" } },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              dense: "",
                                              flat: "",
                                              outlined: "",
                                              "hide-details": "auto",
                                              label: _vm.$t(
                                                "standardFrameworkLabel"
                                              ),
                                              items: _vm.activeFrameworkItems
                                            },
                                            model: {
                                              value: _vm.reportState,
                                              callback: function($$v) {
                                                _vm.reportState = $$v
                                              },
                                              expression: "reportState"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "4" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              "hide-details": "auto",
                                              label: _vm.$t(
                                                "standardGradeLabel"
                                              ),
                                              items: _vm.gradeItems
                                            },
                                            model: {
                                              value: _vm.reportGrade,
                                              callback: function($$v) {
                                                _vm.reportGrade = $$v
                                              },
                                              expression: "reportGrade"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.$vuetify.breakpoint.mdAndUp
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "4" } },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              dense: "",
                                              flat: "",
                                              outlined: "",
                                              "hide-details": "auto",
                                              label: _vm.$t(
                                                "standardSubjectLabel"
                                              ),
                                              items: _vm.subjectItems
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c("span", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            item.text
                                                          )
                                                        }
                                                      })
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              1475939010
                                            ),
                                            model: {
                                              value: _vm.reportSubject,
                                              callback: function($$v) {
                                                _vm.reportSubject = $$v
                                              },
                                              expression: "reportSubject"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "4" } },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              dense: "",
                                              flat: "",
                                              outlined: "",
                                              "hide-details": "auto",
                                              label: _vm.$t(
                                                "standardCategoryLabel"
                                              ),
                                              items: _vm.categoryItems
                                            },
                                            on: {
                                              blur: _vm.validate,
                                              focus: _vm.validate
                                            },
                                            model: {
                                              value: _vm.reportCategory,
                                              callback: function($$v) {
                                                _vm.reportCategory = $$v
                                              },
                                              expression: "reportCategory"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "4" } },
                                        [
                                          _c("pb-date-time-range-field", {
                                            ref: "dateTimeRange",
                                            attrs: {
                                              dense: true,
                                              "hide-details": "auto",
                                              start: _vm.reportStart,
                                              end: _vm.reportEnd,
                                              rules: _vm.dateRangeRules
                                            },
                                            on: {
                                              "update:start": function($event) {
                                                _vm.reportStart = $event
                                              },
                                              "update:end": function($event) {
                                                _vm.reportEnd = $event
                                              },
                                              blur: _vm.validate,
                                              focus: _vm.validate
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.$currentUser.isAdmin &&
                              !_vm.showStandardTeachers
                                ? _c(
                                    "v-row",
                                    [
                                      _c("v-col", { attrs: { cols: "12" } }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "fill-width text-center font-weight-bold"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.teacherName) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.showStandardLessons && !_vm.showStandardTeachers
                        ? _c("v-data-table", {
                            attrs: {
                              "multi-sort": false,
                              loading: _vm.loading,
                              headers: _vm.tableHeaders,
                              items: _vm.standards,
                              "item-key": "dbid",
                              height: _vm.tableHeight,
                              search: _vm.searchText,
                              "fixed-header": "",
                              "hide-default-footer": "",
                              "disable-pagination": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.desc",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c("td", [
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(item.desc)
                                          }
                                        })
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "item.count",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c("td", { staticClass: "text-center" }, [
                                        _vm._v(" " + _vm._s(item.count) + " ")
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "item.dates",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "td",
                                        { staticClass: "text-center" },
                                        [
                                          _vm.hasMoreThanOne(item.dates) &&
                                          _vm.$currentUser.isTeacher
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "standard-date active",
                                                  attrs: {
                                                    tabindex: _vm.$currentUser
                                                      .isTeacher
                                                      ? 0
                                                      : -1,
                                                    role: _vm.$currentUser
                                                      .isTeacher
                                                      ? "button"
                                                      : 0
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      $event.stopPropagation()
                                                      _vm.$currentUser
                                                        .isTeacher &&
                                                        _vm.doLoadStandardLessons(
                                                          item,
                                                          "all"
                                                        )
                                                    },
                                                    keyup: function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      _vm.$currentUser
                                                        .isTeacher &&
                                                        _vm.doLoadStandardLessons(
                                                          item,
                                                          "all"
                                                        )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("viewAllLabel")
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._l(item.dates, function(dt, i) {
                                            return _c(
                                              "div",
                                              {
                                                key: i,
                                                class: {
                                                  "standard-date":
                                                    _vm.$currentUser.isTeacher
                                                },
                                                attrs: {
                                                  tabindex: _vm.$currentUser
                                                    .isTeacher
                                                    ? 0
                                                    : -1,
                                                  role: _vm.$currentUser
                                                    .isTeacher
                                                    ? "button"
                                                    : 0
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    $event.stopPropagation()
                                                    _vm.$currentUser
                                                      .isTeacher &&
                                                      _vm.doLoadStandardLessons(
                                                        item,
                                                        dt,
                                                        i + 1
                                                      )
                                                  },
                                                  keyup: function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    _vm.$currentUser
                                                      .isTeacher &&
                                                      _vm.doLoadStandardLessons(
                                                        item,
                                                        dt,
                                                        i + 1
                                                      )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.stringifyDate(dt, true)
                                                  )
                                                )
                                              ]
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        : _vm._e(),
                      _vm.showStandardTeachers
                        ? _c("v-data-table", {
                            key: _vm.fetchStandardsReport,
                            staticClass: "standard-teachers",
                            attrs: {
                              "multi-sort": false,
                              loading: _vm.loading,
                              headers: _vm.adminTableHeaders,
                              items: _vm.teacherItems,
                              "item-key": "id",
                              height: _vm.tableHeight,
                              search: _vm.teacherSearchText,
                              "fixed-header": "",
                              "hide-default-footer": "",
                              "disable-pagination": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "body.prepend",
                                  fn: function() {
                                    return [
                                      _c(
                                        "tr",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.standardsTotalCounts.name
                                              )
                                            )
                                          ]),
                                          _vm._l(_vm.adminSubjects, function(
                                            s,
                                            i
                                          ) {
                                            return _c(
                                              "td",
                                              {
                                                key: "standardTotalCounts" + i
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.standardsTotalCounts[
                                                      s.name
                                                    ]
                                                  )
                                                )
                                              ]
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "item.name",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "td",
                                        {
                                          class: {
                                            "standard-date":
                                              item.id !== "standards"
                                          },
                                          attrs: { tabindex: "0" },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.doShowTeacherStandards(
                                                item
                                              )
                                            },
                                            keyup: function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.doShowTeacherStandards(
                                                item
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        : _vm._e(),
                      _c("lesson-lists-table", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.showStandardLessons &&
                              !_vm.showStandardTeachers,
                            expression:
                              "showStandardLessons && !showStandardTeachers"
                          }
                        ],
                        ref: "lessonListsTable",
                        attrs: {
                          items: _vm.lessonListData,
                          search: _vm.lessonListSearchText,
                          excludeHeaders: [
                            "extraLesson",
                            "dateLabel",
                            "day",
                            "sorter",
                            "lessonNum"
                          ]
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("pb-confirm", { ref: "confirm" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }