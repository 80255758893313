
import LessonListsTable from '@/components/lessonlists/LessonListsTable.vue';
import { grades, tableWidths } from '@/constants';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import WebUtils from '@/utils/web-utils';
import ld from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ReportingMixin from '@/mixins/reporting-mixin';
import PageLifeCycleMixin from '@/mixins/page-lifecycle-mixin';

const settings = namespace('settings');
const classes = namespace('classes');
const reporting = namespace('reporting');

@Component({
  mixins: [PageLifeCycleMixin, ReportingMixin],
  components: {
    LessonListsTable
  }
})
export default class Reporting extends Vue {
    @reporting.Action
    loadStandardsReport!: () => Promise<any>;

    @reporting.Action
    loadStandardLessons!: (params: any) => Promise<any>;

    @reporting.Action
    exportCSV!: () => Promise<any>;

    @reporting.Action
    exportPDF!: () => Promise<any>;

    @classes.Action
    loadClasses!: (params?: any) => Promise<any>;

    @reporting.Getter
    standardLessonList!: Array<any>;

    @reporting.Getter
    categories!: Array<any>;

    @reporting.Getter
    subjects!: Array<any>;

    @reporting.Getter
    standards!: Array<any>;

    @classes.Getter('getClassItems')
    classItems!: Array<any>;

    @settings.Getter('getActiveFrameworkItems')
    activeFrameworkItems!: Array<any>;

    @reporting.Getter
    adminTeachers!: Array<any>;

    @reporting.Getter
    adminSubjects!: Array<any>;

    @settings.Getter('getBrowserHeight')
    browserHeight!: number;

    @settings.Getter('getStandardSettings')
    standardSettings!: any;

    @classes.Mutation('setHaveClass')
    setHaveClass!: (haveClass: boolean) => void;

    key = '';
    rangeError = '';

    searchText!: string;
    teacherSearchText!: string;
    lessonListSearchText!: string;
    teacherId!: number;
    showStandardLessons!: boolean;
    showStandardTeachers!: boolean;

    loading = false;
    standardLessonListModified:any[] = [];
    localSlideGroupModel = null;

    $refs!: {
      lessonListsTable: LessonListsTable,
      searchForm: HTMLElement,
      reportingForm: Vue & { validate: () => boolean, resetValidation: () => void },
      dateTimeRange: Vue & { validate: () => boolean, resetValidation: () => void },
    }

    get dateRangeRules() {
      this.rangeError = '';
      return [() => this.rangeError];
    }

    get tableHeaders() {
      return [
        {
          width: tableWidths.mediumText,
          text: this.$t('idLabel'),
          value: 'id'
        },
        {
          text: this.$t('descriptionLabel'),
          align: 'start',
          value: 'desc'
        },
        {
          width: tableWidths.mediumText,
          text: this.$t('timesTaughtLabel'),
          align: 'center',
          value: 'count'
        },
        {
          width: tableWidths.fullDate,
          text: this.$t('dateLabel'),
          align: 'center',
          value: 'dates'
        }
      ];
    }

    get headers() {
      return [
        { sortable: false, width: '1%', value: 'action' },
        { text: this.$t('classLabel'), value: 'className', width: '13%', sortable: false },
        { text: this.$t('yearLabel'), value: 'yearName', width: '10%', sortable: false },
        { text: this.$t('dateLabel'), value: 'date', width: '10%', sortable: false },
        { text: this.$t('dayLabel'), value: 'dayOfWeek', width: '10%', sortable: false },
        { text: this.$t('lessonLabel'), value: 'search', width: '70%', sortable: false, align: 'center' }
      ];
    }

    get adminTableHeaders() {
      return [{
        width: 150,
        text: this.reportStateText + '\n (' + this.reportGradeText + ')',
        value: 'name'
      }].concat(this.adminSubjects.map(subject => {
        return {
          width: 50,
          text: subject.name,
          value: subject.name,
          class: 'standard-teachers-header'
        }
      }));
    }

    get fetchStandardsReport() {
      return this.$store.state.reporting.fetchStandardsReport;
    }

    set fetchStandardsReport(val: string) {
      this.$store.commit('reporting/setFetchStandardsReport', val);
    }

    get reportGrade() {
      return +this.$store.state.reporting.reportGrade;
    }

    set reportGrade(val: number) {
      this.$store.commit('reporting/setReportGrade', val);
    }

    get reportGradeText() {
      const item = this.gradeItems.find(g => g.value === this.reportGrade);
      return item ? item.text : '';
    }

    get reportState() {
      return this.$store.state.reporting.reportState;
    }

    set reportState(val: string) {
      this.$store.commit('reporting/setReportState', val);
      this.reportSubject = 'VA2';
      this.reportCategory = 'VA';
    }

    get reportStateText() {
      const item = this.activeFrameworkItems.find(f => f.value === this.reportState);
      return item ? item.text : '';
    }

    get reportSubject() {
      return this.$store.state.reporting.reportSubject;
    }

    set reportSubject(val: string) {
      this.$store.commit('reporting/setReportSubject', val);
    }

    get reportCategory() {
      return this.$store.state.reporting.reportCategory;
    }

    set reportCategory(val: string) {
      this.$store.commit('reporting/setReportCategory', val);
    }

    get reportClassId() {
      return this.$store.state.reporting.reportClassId || 0;
    }

    set reportClassId(val: number) {
      this.$store.commit('reporting/setReportClassId', val);
    }

    get reportStart() {
      return this.$store.state.reporting.reportStart || '';
    }

    set reportStart(val: string) {
      this.$store.commit('reporting/setReportStart', val);
    }

    get reportEnd() {
      return this.$store.state.reporting.reportEnd || '';
    }

    set reportEnd(val: string) {
      this.$store.commit('reporting/setReportEnd', val);
    }

    get reportClassText() {
      const item:any = this.localClassItems.find(c => c.value === this.reportClassId);
      return item ? item.text : '';
    }

    get subjectItemsText() {
      const item:any = this.subjectItems.find(s => s.value === this.reportSubject);
      return item ? item.text : this.$t('noneLabel');
    }

    get categoryItemsText() {
      const item:any = this.categoryItems.find(c => c.value === this.reportCategory);
      return item ? item.text : '';
    }

    @Watch('standards')
    @Watch('teacherItems')
    onListChange() {
      WebUtils.initMathJax();
    }

    @Watch('reportClassId')
    @Watch('reportState')
    @Watch('reportSubject')
    @Watch('reportCategory')
    @Watch('reportCategory')
    @Watch('reportStart')
    @Watch('reportEnd')
    @Watch('reportGrade')
    @Watch('teacherId')
    onReportGradeChange() {
      this.$nextTick(() => {
        this.doFetch();
      });
    }

    @Watch('reportClassId')
    onReportClassIdChange() {
      this.$store.commit('reporting/setClassName', this.reportClassText);
    }

    @Watch('reportSubject')
    onReportSubjectChange() {
      this.$store.commit('reporting/setSubjectName', this.subjectItemsText);
    }

    @Watch('reportState')
    onReportStateChange() {
      this.$store.commit('reporting/setStateName', this.reportStateText);
    }

    validate() {
      this.rangeError = '';
      if (CommonUtils.hasNoText(this.reportStart)) {
        this.rangeError = this.$t('enterStartDate') as string;
      } else if (CommonUtils.hasNoText(this.reportEnd)) {
        this.rangeError = this.$t('enterEndDate') as string;
      }
      return this.$refs.reportingForm.validate() && this.$refs.dateTimeRange.validate();
    }

    get reportDates() {
      return {
        start: this.reportStart,
        end: this.reportEnd
      }
    }

    set reportDates(val: any) {
      this.reportStart = val.start;
      this.reportEnd = val.end;
    }

    get subjectItems() {
      const items = [{
        value: 'VA2',
        text: this.$t('allSubjectsLabel')
      }];
      if (this.$currentUser.isTeacher || this.teacherId > 0) {
        this.subjects.forEach(subject => {
          if (this.standardSettings.currentSubject === this.reportSubject && subject.subjectId === this.reportSubject) {
            items.push({
              value: subject.subjectId,
              text: subject.subjectDesc
            });
          } else if (this.standardSettings.currentSubject !== this.reportSubject || this.reportSubject === 'VA2') {
            items.push({
              value: subject.subjectId,
              text: subject.subjectDesc
            });
          }
        });
      }
      return items;
    }

    get categoryItems() {
      return [{
        value: 'VA',
        text: this.$t('allCategoriesLabel')
      }].concat(this.$currentUser.isAdmin && this.teacherId === 0 ? [] : this.categories.filter(
        category =>
          this.reportSubject === 'VA2' || this.reportSubject === category.subject
      ).map(category => {
        return {
          value: category.categoryId,
          text: category.categoryDesc
        }
      }));
    }

    get gradeItems() {
      return grades.map(grade => {
        return {
          value: +grade.value,
          text: grade.text
        }
      }).filter(grade => grade.value < 80 && grade.value >= -1);
    }

    get stringifyDate() {
      return DateTimeUtils.stringifyDate;
    }

    get localClassItems() {
      return [{
        value: 0,
        text: this.$t('allClassesLabel')
      }].concat(this.$currentUser.isAdmin && this.teacherId === 0 ? [] : this.classItems);
    }

    get standardsTotalCounts() {
      const standardItem: any = {
        name: this.$t('standardsLabel'),
        id: 'standards'
      }
      this.adminSubjects.forEach(s => {
        standardItem[s.name] = s.count;
      })
      return standardItem;
    }

    get teacherItems() {
      const items: Array<any> = [];
      this.adminTeachers.forEach(t => {
        const teacherItem = {
          ...t,
          name: this.getName(t)
        };
        this.adminSubjects.forEach((s, i) => {
          teacherItem[s.name] = teacherItem.counts[i];
        });
        delete teacherItem.counts;
        items.push(teacherItem);
      });
      return items;
    }

    get teacherName() {
      const item = this.teacherItems.find(teacher => +teacher.id === this.teacherId);
      return item ? item.name : 'All Teachers';
    }

    get lessonListData() {
      return this.standardLessonListModified;
    }

    get tableHeight() {
      try {
        if (CommonUtils.hasText(this.key)) {
          const form = this.$refs.searchForm;
          return this.browserHeight - form.getBoundingClientRect().bottom;
        } else {
          return this.browserHeight - 315;
        }
      } catch (e) {
        return this.browserHeight - 315;
      }
    }

    getName(teacher: any) {
      let name = '';
      if (CommonUtils.hasText(teacher.first)) {
        name += teacher.first;
      }
      if (CommonUtils.hasText(teacher.last)) {
        name += ' ' + teacher.last;
      }
      if (CommonUtils.hasNoText(name)) {
        name += teacher.email;
      }
      return name.trim();
    }

    hasMoreThanOne(items: Array<any>) {
      return CommonUtils.isNotEmpty(items) && items.length > 0;
    }

    doLoadStandardLessons(item: any, date: string, count?: number) {
      const params: any = {
        DBId: item.dbid,
        startDate: date === 'all' ? this.reportStart : date,
        endDate: date === 'all' ? this.reportEnd : date
      };
      this.showStandardLessons = false;
      CommonUtils.showLoading();
      const lessonDate = date;
      return Promise.resolve().then(() => {
        if ((item.linkedLessonsOrUnits && lessonDate === 'all') || (item.linkedLessonsOrUnits && item.linkedLessonsOrUnits[lessonDate + '+' + count])) {
          return this.loadStandardLessons(params).then(() => {
            if (lessonDate === 'all') {
              const linkedLessons = [];
              for (const key in item.linkedLessonsOrUnits) {
                if (Object.prototype.hasOwnProperty.call(item.linkedLessonsOrUnits, key)) {
                  const element = item.linkedLessonsOrUnits[key];
                  linkedLessons.push(element);
                }
              }
              this.$store.commit('reporting/addLinkedStandardLessons', linkedLessons);
            } else {
              this.$store.commit('reporting/addLinkedStandardLessons', [item.linkedLessonsOrUnits[lessonDate + '+' + count]]);
            }
            return Promise.resolve();
          });
        } else {
          return this.loadStandardLessons(params);
        }
      })
        .then(() => {
          if (this.$refs.lessonListsTable) {
            this.$refs.lessonListsTable.localInfiniteScrollResetAllowed = true;
          }
          this.populateListData();
          this.showStandardLessons = true;
        })
        .finally(CommonUtils.hideLoading);
    }

    doExportCSV() {
      if (this.validate()) {
        CommonUtils.showLoading();
        this.exportCSV().finally(CommonUtils.hideLoading);
      }
    }

    doExportPDF() {
      if (this.validate()) {
        CommonUtils.showLoading();
        this.exportPDF().finally(CommonUtils.hideLoading);
      }
    }

    doShowTeacherStandards(item: any) {
      this.teacherId = item.id;

      CommonUtils.showLoading();
      this.setHaveClass(false);
      this.loadClasses({ currentTeacherId: item.id, yearId: item.currentSchoolYearId }).finally(() => {
        CommonUtils.hideLoading();
      });
    }

    showActions(event: any, itemData: any) {
      this.$eventBus.$emit('actions', {
        type: 'lesson',
        event,
        input: {
          exclude: [],
          loadData: true,
          data: itemData.rawDayObject
        }
      });
    }

    doFetch() {
      if (this.validate()) {
        this.loading = true;
        this.fetchStandardsReport = CommonUtils.generateUUID();
        this.loadStandardsReport().finally(() => {
          this.loading = false;
        });
      }
    }

    back() {
      if (this.$currentUser.isTeacher) {
        this.showStandardLessons = !this.showStandardLessons;
      } else {
        this.reportSubject = 'VA2';
        this.reportCategory = 'VA';
        this.reportClassId = 0;
        this.teacherId = 0;
      }
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 10)).then(() => {
          this.key = CommonUtils.generateUUID();
        });
      })
    }

    updated() {
      const headers = document.querySelectorAll('.standard-teachers .v-data-table-header tr th:not(:first-child) span');
      headers.forEach((header) => {
        const textContent = header.textContent;
        header.innerHTML = textContent as string;
        header.setAttribute('title', header.textContent || '');
      });
    }

    doInit() {
      CommonUtils.showLoading();
      this.loading = true;
      this.showStandardLessons = false;
      this.loadStandardsReport().finally(() => {
        this.$store.commit('reporting/setClassName', this.reportClassText);
        this.$store.commit('reporting/setSubjectName', this.subjectItemsText);
        this.$store.commit('reporting/setStateName', this.reportStateText);

        this.loading = false;
        CommonUtils.hideLoading();
      });
    }

    mounted() {
      try {
        this.rangeError = '';
        this.$refs.reportingForm.resetValidation();
        this.$refs.dateTimeRange.resetValidation();
        this.validate();
      } catch (e) {
      }
      this.doInit();
      this.$nextTick(() => {
        this.$eventBus.$on('userModeChanged', this.doInit);
        this.$eventBus.$on('lessonHasBeenSave', () => {
          this.$refs.lessonListsTable.localInfiniteScrollResetAllowed = false;
          this.populateListData();
        });
        this.key = CommonUtils.generateUUID();
      })
    }

    destroyed() {
      this.$eventBus.$off('userModeChanged', this.doInit);
      this.$eventBus.$off('lessonHasBeenSave');
    }

    populateListData() {
      const modifiedStandardLessonList:any = [];
      for (const lessonList of this.standardLessonList) {
        const dayObject = ld.cloneDeep(lessonList);
        delete dayObject.rawDayObject;
        const rawDayObject = ld.cloneDeep(lessonList.rawDayObject);
        rawDayObject.dayObject = dayObject;
        modifiedStandardLessonList.push(rawDayObject);
      }
      this.standardLessonListModified = modifiedStandardLessonList;
    }
}
